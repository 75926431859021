#video {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: -1;
  /* filter: blur(5px); */
}

.hero {
  height: 100vh;
  width: 100%;
  object-fit: contain;
}

.hero .content {
  text-align: center;
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
  top: 45vh;
}

.hero .content h1 {
  font-size: 4rem;
  color: #fff;
}

.hero p {
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  color: #fff;
}

.hero .btn {
  margin: 1rem .2rem;
}

@media screen and (max-width:640px) {
  .content h1 {
      font-size: 3rem;
  }

  .content p {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
  }
  
  .hero .content h1 {
    font-size: 3rem;
    color: #fff;
  }
}
