.calculator {
  width: 100%;
  padding: 6rem 1rem;
  background: rgba(145, 180, 150, 1);
}

.card-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(2, 1fr);
}

.card-container .card {
  border: 2px solid white;
}

.card-add {
  border: 2px solid white;
  max-width: 1140px;
  display: block;
  margin: 40px auto;
}

.card {
  text-align: center;
  padding: 1rem;
  background-color: rgba(145, 180, 150, 1);
  border-radius: 10px;
}

.card:hover {
  background-color: #749078;
}

.card h3 {
  font-size: 1.4rem;
  padding: 1rem;
  color: #000;
}

.card p {
  font-size: 1.4rem;
  padding: 1rem;
  color: #000;
}

.card .bar {
  border-bottom: 1px solid #000;
  width: 10%;
  margin: 1.5rem auto;
  display: block;
}

.card .dollars {
  font-size: 4rem;
  font-weight: 600;
  padding: 1rem;
}

.pricing p {
  color: #000;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.card .btn {
  display: block;
  width: 80%;
  margin: 2rem auto;
}

@media screen and (max-width: 980px) {
  .card-container {
      max-width: 90%;
      margin: auto;
      grid-template-columns: 1fr;
  }
}
