.bg {
  background: rgba(145, 180, 150, 1);
}

label {
  color: #000;
}

.form-header {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
}

.form-header-faq {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #749078;
}

form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: auto;
  max-width: 600px;
  padding-bottom: 40px;
}

form label {
  margin-bottom: .5rem;
}

form input, textarea {
  margin-bottom: 1rem;
  padding: 10px 18px;
  font-size: 1.2rem;
  font-family: 'Outfit', sans-serif;
  background: #fff;
  border-color: rgba(255,255,255,.1);
  color: #000;
  border-radius: 10px;
}

.btn {
  border-radius: 10px;
}

.section {
  padding-top: 30px;

}