.hero {
  background: rgba(0, 0, 0, .3);
  height: 100vh;
  width: 100%;
  position: relative;
}

.hero:before {
  content:'';
  background: url('../assets/pic2.jpg') no-repeat center center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  left: 0;
  z-index: -1;
}

.hero .content {
  text-align: center;
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
  top: 45vh;
}

.hero .content h1 {
  font-size: 4rem;
  color: #fff;
}

.hero p {
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  color: #fff;
}

.hero .btn {
  margin: 1rem .2rem;
}

.hero .buttons {
  display: flex;
  justify-content: center;
}

@media screen and (max-width:640px) {
  .content h1 {
      font-size: 3rem;
  }

  .content p {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
  }
  
  .hero .content h1 {
    font-size: 3rem;
    color: #fff;
    margin-bottom: 20px;
  }
  .hero .btn {
    margin-bottom: 10px;
  }
}
