
.header {
  position: fixed;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(250, 250, 250, .6);
  z-index: 10;
}

.header-bg {
  background-color: rgb(145, 180, 150);
  transition: .5s;
}

.nav-menu {
  display: flex; 
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu a:hover {
  color: #3d4e40;
}

.nav-menu a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 1240px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }
  
  .nav-menu {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: -100%;
    text-align: center;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    z-index: -1;
    background: rgba(145, 180, 150, .9);
  }

  .nav-menu a:hover {
    color: #3d4e40;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-menu li {
    padding: 1rem 0;
  }

  .nav-menu a {
    font-size: 2rem;
  }
}